import { mapGetters } from 'vuex';
import SelectOfferForm from '@/fragments/SelectOfferForm';
import SelectedOfferCard from '@/components/Offers/SelectedOfferCard';
export default {
  name: 'SelectOffer',
  components: {
    SelectOfferForm,
    SelectedOfferCard
  },
  computed: { ...mapGetters('user', ['loggedIn', 'name']),
    ...mapGetters('offers', ['selectedOffer', 'loading', 'submited', 'error'])
  },
  methods: {
    goBack() {
      this.$router.push({
        path: '/'
      });
    }

  }
};