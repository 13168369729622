import OfferPrices from '@/components/Offers/OfferPrices';
import PartnerMeny from '@/components/Partners/PartnerMenu';
export default {
  name: 'SelectedOfferCard',
  components: {
    OfferPrices,
    PartnerMeny
  },
  props: {
    offer: {
      type: Object,
      required: true,
      default: () => {}
    }
  },

  data() {
    return {
      expanded: false
    };
  },

  methods: {
    imageUrl(imageUrl) {
      return require('../../assets/partners/' + imageUrl);
    },

    expandBody() {
      this.expanded = !this.expanded;
    }

  }
};