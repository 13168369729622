import store from '@/store';
import { mapGetters, mapActions, useStore } from 'vuex';
import { useForm } from 'vee-validate';
import FormInput from '@/components/FormInput';
import Message from '@/components/Message';
export default {
  name: 'SelectOfferForm',
  components: {
    FormInput,
    Message
  },

  setup() {
    const store = useStore();
    const {
      handleSubmit,
      isSubmitting
    } = useForm();
    const onSubmit = handleSubmit(() => {
      store.dispatch('offers/submitOfferForm');
    });
    return {
      onSubmit,
      isSubmitting
    };
  },

  data() {
    return {
      autoFilled: false
    };
  },

  computed: { ...mapGetters('user', ['loggedIn', 'name']),
    ...mapGetters('offers', Object.keys(store._modulesNamespaceMap['offers/']._rawModule.getters))
  },

  created() {
    if (this.loggedIn && this.name) {
      this.loadUserData();
      this.autoFilled = true;
    }
  },

  methods: { ...mapActions('offers', ['loadUserData'])
  }
};